import { type Affiliate, type Profile } from '@prisma/client'

// Server-sent event - cannot use `:` as separator

// Affiliate
export function eventName_affiliate_newMessage(recipientId: Affiliate['id']) {
	return `affiliate-new-message-${recipientId}`
}

export function eventName_affiliate_updateNewMessageCount(
	recipientId: Affiliate['id'],
) {
	return `affiliate-update-new-message-count-${recipientId}`
}

export function eventName_affiliate_updateRecipientRead(
	recipientId: Affiliate['id'],
) {
	return `affiliate-update-recipient-read-${recipientId}`
}

// User
export function eventName_user_deleteConversation(recipientId: Profile['id']) {
	return `user-delete-conversation-${recipientId}`
}

export type UserNewLikesData = {
	isReset: boolean
	newLikes: number
	newMatches: number
}

export function eventName_user_newLikes(recipientId: Profile['id']) {
	return `user-new-likes-${recipientId}`
}

export function eventName_user_newMessage(recipientId: Profile['id']) {
	return `user-new-message-${recipientId}`
}

export function eventName_user_resetConversationCount(
	recipientId: Profile['id'],
) {
	return `user-reset-conversation-count-${recipientId}`
}

export function eventName_user_updateConversationContent(
	recipientId: Profile['id'],
) {
	return `user-update-conversation-content-${recipientId}`
}

export function eventName_user_updateHasNoPhoto(recipientId: Profile['id']) {
	return `user-update-has-no-photo-${recipientId}`
}

export function eventName_user_updateNewMessageCount(
	recipientId: Profile['id'],
) {
	return `user-update-new-message-count-${recipientId}`
}

export function eventName_user_updateRecipientRead(recipientId: Profile['id']) {
	return `user-update-recipient-read-${recipientId}`
}

// Other
export type NotifyData = {
	clickUrl?: string
	imageUrl: string
	message: string
	title: string
	variant: 'info' | 'success'
}

export function eventName_notify(recipientId: number) {
	return `notify-${recipientId}`
}
